import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tablecomponent from "../../Components/Common/TableComponent/TableComponent";
import Title from "../../Components/Common/Title/Title";
import {
  Chip,
  CircularProgress,
  Snackbar,
  TextField,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { fetchOnboardingData, fetchAIVerificationStatus } from "../../api/api";
import style from "./UserOnboarding.module.css";
import { useGridApiRef } from "@mui/x-data-grid";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { aiVerificationStatus } from "../../api/config";

const UserOnboarding = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const today = new Date();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const apiRef = useGridApiRef();

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError(null);
  };

  const handleToastMessage = (messageText) => {
    setMessage(messageText);
    setOpen(true);
  };

  useEffect(() => {
    const getOnboardingData = async () => {
      setLoading(true);
      try {
        const data = await fetchOnboardingData();
        setUserData(data.data);
        setFilteredData(data.data);
      } catch (error) {
        console.error("Error fetching onboarding data:", error);
      } finally {
        setLoading(false);
      }
    };

    getOnboardingData();
  }, []);

  // useEffect(() => {
  //   if (!startDate && !endDate) {
  //     setFilteredData(userData);
  //   } else if (startDate && endDate && endDate < startDate) {
  //     setError("End date should not be before the start date");
  //     setFilteredData(userData);
  //   } else {
  //     const startUTC = startDate instanceof Date && !isNaN(startDate)
  //       ? new Date(startDate.setHours(0, 0, 0, 0)).toISOString().split('T')[0]
  //       : null;

  //     const endUTC = endDate instanceof Date && !isNaN(endDate)
  //       ? new Date(endDate.setHours(23, 59, 59, 999)).toISOString().split('T')[0]
  //       : null;

  //     if (startUTC && endUTC) {
  //       const filtered = userData.filter((user) => {
  //         const lastUpdateUTC = new Date(new Date(user.lastUpdate).setHours(0, 0, 0, 0)).toISOString().split('T')[0];
  //         return lastUpdateUTC >= startUTC && lastUpdateUTC <= endUTC;
  //       });
  //       setFilteredData(filtered);
  //     }
  //   }
  // }, [startDate, endDate, userData]);
  // useEffect(() => {
  //   console.log("start date", startDate, endDate);
  //   if (!startDate && !endDate) {
  //     setFilteredData(userData);
  //     return;
  //   }

  //   if (startDate && endDate && endDate < startDate) {
  //     setError("End date should not be before the start date");
  //     setFilteredData(userData);
  //     return;
  //   }

  //   if (
  //     startDate instanceof Date &&
  //     endDate instanceof Date &&
  //     !isNaN(startDate) &&
  //     !isNaN(endDate)
  //   ) {
  //     const startOfDay = new Date(startDate);
  //     startOfDay.setHours(0, 0, 0, 0);

  //     const endOfDay = new Date(endDate);
  //     endOfDay.setHours(23, 59, 59, 999);
  //     console.log("start of day", startOfDay, endOfDay);

  //     const filtered = userData.filter((user) => {
  //       console.log("last update date", user.lastUpdate);
  //       const userDate = new Date(user.lastUpdate);
  //       console.log("new date after", userDate);
  //       return userDate >= startOfDay && userDate <= endOfDay;
  //     });

  //     setFilteredData(filtered);
  //     setError("");
  //   }
  // }, [startDate, endDate, userData]);
  useEffect(() => {
    // Month name to number mapping
    const MONTHS = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    // Helper function to parse date string with month names
    const parseSafariDate = (dateString) => {
      if (!dateString) return null;

      try {
        // If it's already a Date object
        if (dateString instanceof Date) {
          return isNaN(dateString) ? null : dateString;
        }

        // Handle format: "19/Dec/2024 18:47:43"
        if (dateString.includes("/")) {
          const [day, month, yearTime] = dateString.split("/");
          if (month in MONTHS) {
            const [year, time = "00:00:00"] = yearTime.split(" ");
            const [hours, minutes, seconds] = time.split(":");

            return new Date(
              parseInt(year),
              MONTHS[month],
              parseInt(day),
              parseInt(hours || 0),
              parseInt(minutes || 0),
              parseInt(seconds || 0)
            );
          }
        }

        // Fallback to default parsing
        const date = new Date(dateString);
        return isNaN(date) ? null : date;
      } catch (error) {
        console.error("Error parsing date:", error, dateString);
        return null;
      }
    };

    if (!startDate && !endDate) {
      setFilteredData(userData);
      return;
    }

    if (startDate && endDate && endDate < startDate) {
      setError("End date should not be before the start date");
      setFilteredData(userData);
      return;
    }

    if (
      startDate instanceof Date &&
      endDate instanceof Date &&
      !isNaN(startDate) &&
      !isNaN(endDate)
    ) {
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      const filtered = userData.filter((user) => {
        const userDate = parseSafariDate(user.lastUpdate);

        if (!userDate) {
          console.warn("Could not parse date:", user.lastUpdate);
          return false;
        }

        return userDate >= startOfDay && userDate <= endOfDay;
      });

      setFilteredData(filtered);
      setError("");
    }
  }, [startDate, endDate, userData]);

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "emailId",
      headerName: "Email-ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "applicationStatus",
      headerName: "Application Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{
            backgroundColor:
              params.value === "AI Verification Failed"
                ? "#FFE0E5"
                : params.value === "Rejected"
                ? "#FFE0E5"
                : params.value === "In Progress"
                ? "#FFFFC9"
                : params.value === "Verified"
                ? "#D9FBE7"
                : "#F1F1F1",
            color:
              params.value === "AI Verification Failed"
                ? "#FE718B"
                : params.value === "Rejected"
                ? "#FE718B"
                : params.value === "In Progress"
                ? "#FFA83D"
                : params.value === "Verified"
                ? "#109A48"
                : "#909090",
            borderColor: "transparent",
          }}
          variant="outlined"
        />
      ),
    },
    {
      field: "lastUpdate",
      headerName: "Last Update",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "aiVerificationStatus",
      headerName: "Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value) {
          return <CheckCircleOutlineOutlinedIcon color="success" />;
        } else {
          return <CancelOutlinedIcon color="error" />;
        }
      },
    },
  ];

  const rows = filteredData.map((user) => ({
    id: user.kycdocumentid,
    userName: user.userName,
    source: user.cardType,
    emailId: user.email,
    applicationStatus: user.applicationStatus,
    lastUpdate: user.lastUpdate,
    aiVerificationStatus: user.aiVerificationStatus,
  }));

  const handleRowClick = (params) => {
    if (
      params.row.applicationStatus === "Application Incomplete" ||
      params.row.applicationStatus === "In Complete"
    ) {
      handleToastMessage("Cannot open Application Incomplete profile");
    } else {
      navigate(`/userOnboarding/${params.row.id}`);
    }
  };

  const clearAllFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilteredData(userData);
    setError(null);
    if (apiRef.current) {
      apiRef.current.setFilterModel({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [],
      });
    }
  };

  const handleVerifyAll = async () => {
    try {
      setIsVerifying(true);
      const response = await fetch(aiVerificationStatus, { method: "PATCH" });
      if (!response.ok) {
        handleToastMessage("No records to verify");
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value);
        const records = buffer.split("}").filter(Boolean);

        for (const record of records) {
          const parsedRecord = JSON.parse(record + "}");
          updateRowStatus(parsedRecord);
        }
      }
      setIsVerifying(false);
    } catch (error) {
      console.error("Error fetching AI Verification data:", error);
      setIsVerifying(false);
    }
  };

  const updateRowStatus = (data) => {
    setUserData((prevRows) =>
      prevRows.map((row) =>
        row.kycdocumentid === data.kycdocumentid
          ? {
              ...row,
              aiVerificationStatus: data.aiVerificationStatus,
              applicationStatus: data.applicationStatus,
            }
          : row
      )
    );
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
      />
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          onClose={handleCloses}
          message={error}
        />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Title title={"User Onboarding"} />
        <div style={{ display: "flex", gap: "20px" }}>
          <Tooltip title="Clear All Filters" arrow>
            <IconButton
              onClick={clearAllFilters}
              size="small"
              style={{ width: "45px", height: "50px" }}
            >
              <FilterAltOffIcon />
            </IconButton>
          </Tooltip>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(new Date(date))}
              inputFormat="MM/dd/yyyy"
              maxDate={today}
              className={style.datePickerField}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(new Date(date))}
              inputFormat="MM/dd/yyyy"
              maxDate={today}
              className={style.datePickerField}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <Button
            variant="contained"
            onClick={handleVerifyAll}
            disabled={isVerifying}
            style={{
              margin: "10px 30px 10px 0px",
              backgroundcolor: "#2466F4",
              width: "125px",
            }}
          >
            {isVerifying ? "Verifying..." : "Verify All"}
          </Button>
        </div>
      </div>

      {loading ? (
        <div className={style["circular-progress"]}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Tablecomponent
            rows={rows}
            columns={columns}
            onRowClick={handleRowClick}
            apiRef={apiRef}
          />
        </div>
      )}
    </>
  );
};

export default UserOnboarding;
