import React, { useState, useRef, useEffect } from 'react';
import Title from "../../Components/Common/Title/Title";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from './FaqDocument.module.css';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddButton from '../../assets/AddButtton.svg';
import EditButton from '../../assets/EditButton.svg';
import FAQFileUpload from '../../assets/FAQFileUpload.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import FileUpload from '../../Components/Common/FileUpload/FileUpload';
import { fetchAccordianData,deleteFaq,updateFaq,addFaq,uploadFaqFile } from '../../api/api';
 
const FaqDocument = () => {
  const [accordions, setAccordions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [openFileDialog, setOpenFileDialog] = useState(false); 
  const [openDialog, setOpenDialog] = useState(false); 
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editingAccordion, setEditingAccordion] = useState(null);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef(null);
 
  const itemsPerPage = 12;
 
  const filteredAccordions = accordions.filter((accordion) =>
    accordion.question.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  const handleCloses = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
 
    setOpen(false);
  };
  const action = (
    <React.Fragment>
    </React.Fragment>
  );
 
  const totalPages = Math.ceil(filteredAccordions.length / itemsPerPage);
 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredAccordions.slice(startIndex, startIndex + itemsPerPage);
 
  const leftAccordions = currentItems.slice(0, 6);
  const rightAccordions = currentItems.slice(6, 12);
  
  const getAccordianData = async () => {
    setLoading(true);
    try {
      const response = await fetchAccordianData();
      setAccordions(response.data.data); 
    } catch (err) {
      console.error("Error in useEffect:", err.message);
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    getAccordianData();
  }, []);
 
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
 
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
 
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget); 
  };
 
  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const handleOpenQuestion = () => {
    setEditingAccordion(null);
    setNewQuestion('');
    setNewAnswer('');
    setOpenDialog(true); 
    setAnchorEl(null);   
  };
 
  const handleCloseDialog = () => {
    setOpenDialog(false); 
  };
 
  const handleOpenFile = () => {
    setAnchorEl(null); 
    setOpenFileDialog(true);
  };
 
  const handleCloseFileDialog = () => {
    setOpenFileDialog(false); 
  };
 
  const handleFileUpload = (file) => {
     setFile(file); 
     handleUploadFile(file);
  };
 
  
  const handleUploadFile = async (file) => {
    setLoading(true);
    try {
      const response = await uploadFaqFile(file, 'aashika'); 
      setMessage("FAQ File Added Successfully");
      setOpen(true);
      setOpenFileDialog(false);
      getAccordianData(); 
    } catch (error) {
      setMessage("File Not Added");
      setOpen(true);
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
      setOpenFileDialog(false);
    }
  };

  const handleOpenDeleteDialog = (accordion) => {
    setEditingAccordion(accordion); 
    setOpenDeleteDialog(true);
  };
 
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setEditingAccordion(null);
  };
 
 

  const handleDeleteAccordion = async () => {
    try {
      await deleteFaq(editingAccordion.id);
      setAccordions(accordions.filter((accordion) => accordion.id !== editingAccordion.id));
      handleCloseDeleteDialog();
      setMessage("FAQ Delete Successful");
      setOpen(true);
    } catch (error) {
      setMessage("FAQ Delete Unsuccessful");
      setOpen(true);
      handleCloseDeleteDialog();
    }
  };
 
  const handleEditAccordion = (accordion) => {
    setEditingAccordion(accordion);
    setNewQuestion(accordion.question);
    setNewAnswer(accordion.answer);
    setOpenDialog(true);
  };
 
 
  const handleSaveEdit = async () => {
    try {
      await updateFaq(editingAccordion.id, newQuestion, newAnswer);
      const updatedAccordions = accordions.map((accordion) =>
        accordion.id === editingAccordion.id
          ? { ...accordion, question: newQuestion, answer: newAnswer }
          : accordion
      );
      setAccordions(updatedAccordions);
      handleCloseDialog();
      setMessage("FAQ Edit Successful");
      setOpen(true);
    } catch (error) {
      setMessage("FAQ Edit Unsuccessful");
      setOpen(true);
    }
  };
 
  const handleSave = async () => {
    try {
      const formattedQuestion = newQuestion.trim().endsWith('?') ? newQuestion : `${newQuestion.trim()}?`;
      await addFaq(formattedQuestion, newAnswer);
      handleCloseDialog();
      getAccordianData();
      setMessage("New FAQ Add Successful");
      setOpen(true);
    } catch (error) {
      setMessage("New FAQ Add Unsuccessful");
      setOpen(true);
    }
  };
 
 
  return (
    <div className="accordions-page">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
        action={action}
      />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: '0px 180px 0px 50px ', alignItems: 'center' }}>
        <Title title="FAQ Document" />
        <div className={style["search-bar"]}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: '300px', float: 'right' }}
          />
          <img
            src={AddButton}
            alt="Add"
            style={{
              padding: '10px',
              backgroundColor: '#3c66fa',
              borderRadius: '8px',
              cursor: 'pointer',
              marginLeft: '30px',
              float: 'right'
            }}
            onClick={handleAddClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenFile} style={{ Padding: '20px', gap: '20px' }}>
              <img src={FAQFileUpload} width='26px' height='26px' />
              Add FAQ File
            </MenuItem>
            <MenuItem onClick={handleOpenQuestion} style={{ Padding: '20px', gap: '20px' }}>
              <img src={EditButton} />
              Add Question
            </MenuItem>
          </Menu>
 
 
          <Dialog open={openFileDialog} onClose={handleCloseFileDialog}>
            <DialogTitle>Upload FAQ File</DialogTitle>
            <DialogContent>
              <FileUpload  onFileUpload={handleFileUpload}/>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFileDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
 
 
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{editingAccordion ? 'Edit Question' : 'Add a New Question'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Question"
                fullWidth
                variant="outlined"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                disabled={editingAccordion}
                sx={{
                  color: editingAccordion ? 'red' : 'black' // Set the text color
                }}
              />
              <TextField
                margin="dense"
                label="Answer"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={newAnswer}
                onChange={(e) => setNewAnswer(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
              <Button onClick={editingAccordion ? handleSaveEdit : handleSave} color="primary">
                {editingAccordion ? 'Save' : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>
 
          <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Delete this FAQ?</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">No</Button>
              <Button onClick={handleDeleteAccordion} color="secondary">Yes</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
 
 
      {loading ? (
        <div className={style["circular-progress"]}>
          <CircularProgress />
        </div>
      ) : (
        <div className={style["accordions-container"]}>
          <div className={style["left-column"]}>
            {leftAccordions.map((accordion, index) => (
              <Accordion key={index} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  className={style["accordion-summary"]}
                  sx={{ backgroundColor: '#F1F1F1' }}
                >
                  <Typography sx={{ flexGrow: 1 }}>{accordion.question}</Typography>
                  <div className={style["accordion-icons"]}>
                    <IconButton onClick={() => handleEditAccordion(accordion)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(accordion)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{accordion.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div className={style["right-column"]}>
            {rightAccordions.map((accordion, index) => (
              <Accordion key={index} sx={{ marginBottom: '20px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 6}-content`}
                  id={`panel${index + 6}-header`}
                  className={style["accordion-summary"]}
                  sx={{ backgroundColor: '#F1F1F1' }}
                >
                  <Typography sx={{ flexGrow: 1 }}>{accordion.question}</Typography>
                  <div className={style["accordion-icons"]}>
                    <IconButton onClick={() => handleEditAccordion(accordion)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(accordion)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{accordion.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      )}
      <div className={style["pagination-container"]}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </div>
  );
};
 
export default FaqDocument;