import React, {useState} from 'react';
import CircleWithText from "../../../Components/CircleWithText/CircleWithText";
import GreenStep from "../../../assets/GreenStep.svg";
import CrossStep from "../../../assets/CrossStep.svg";


const StepIndicator = ({ 
  currentStep, 
  isPersonalInfoVerified, 
  isAddressVerified, 
  isDocumentInfoVerified, 
  isCardAssigned,
  onStepChange  
}) => {
  const handleStepChange = (newStep) => {
    onStepChange(newStep);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "40px",
        marginTop: "30px",
        marginBottom: "40px",
      }}
    >
      <CircleWithText
        text="Personal <br/>Information"
        color="#ffffff"
        textColor="#fff"
        img={isPersonalInfoVerified ? GreenStep : CrossStep}
        isActive={currentStep === 0}
        callBackFuntion={() => handleStepChange(0)}
      />
      <CircleWithText
        text="Address<br/> Information"
        color="#ffffff"
        textColor="#fff"
        img={isAddressVerified ? GreenStep : CrossStep}
        isActive={currentStep === 1}
        callBackFuntion={() => handleStepChange(1)}
      />
      <CircleWithText
        text="Identification<br/> Documents"
        color="#ffffff"
        textColor="#fff"
        img={isDocumentInfoVerified ? GreenStep : CrossStep}
        isActive={currentStep === 2}
        callBackFuntion={() => handleStepChange(2)}
      />
      {isCardAssigned === false && (
        <CircleWithText
          text="Assign<br/> Card"
          color="#ffffff"
          textColor="#fff"
          img={isCardAssigned ? GreenStep : CrossStep}
          isActive={currentStep === 3}  
          callBackFuntion={() => handleStepChange(3)}        
        />
      )}
    </div>
  );
};

export default StepIndicator;