import React, { useEffect, useState } from "react";
import Tablecomponent from "../../Components/Common/TableComponent/TableComponent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import style from "./CustomerSupport.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Snackbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import userAvatar from "../../assets/userAvatar.svg";
import filter from "../../assets/filter.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import customerBox from "../../assets/Boxes.svg";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import Title from "../../Components/Common/Title/Title";
import { fetchFallBackDataApi,fetchTicketChatDataApi,SubmitAnswerApi } from "../../api/api";

const CustomerSupport = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [selectedChatData, setSelectedChatData] = useState({});
  const [rowsData, setRowsData] = useState([]);
  const [isLoadingFallback, setIsLoadingFallback] = useState(true);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null); 
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFallBackData();
  }, [activeFilter]);

  useEffect(() => {
    if (selectedTicketId && selectedChatData === null) {
      fetchTicketChatData();
    }
  }, [selectedTicketId, selectedChatData]);

 
  const fetchFallBackData = async () => {
    setIsLoadingFallback(true);
    try {
      const data = await fetchFallBackDataApi(activeFilter);
      setRowsData(data); 
    } catch (error) {
      console.error("Error fetching fallback data:", error);
    } finally {
      setIsLoadingFallback(false);
    }
  };
  
  const fetchTicketChatData = async () => {
    setIsLoadingChat(true);
    try {
      const response = await fetchTicketChatDataApi(selectedId)
      setSelectedChatData(response);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoadingChat(false);
    }
  };

  const filterUnanswered = () => {
    setActiveFilter("unanswered");
    handleClose(); 
  };

  const filterAnswered = () => {
    setActiveFilter("answered");
    handleClose(); 
  };

  const columns = [
    {
      field: "ticketId",
      headerName: "Ticket ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "channel",
      headerName: "Source",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRowClick = (params) => {
    setSelectedTicketId(params.row.ticketId);
    setSelectedId(params.row.id);
    setSelectedChatData(null);
    setInputValue("");
  };

  const hideChatBot = () => {
    setSelectedTicketId(null);
    setSelectedChatData(null);
  };

  const BotChatMessage = (props) => {
    return (
      <div className={style["chat-message-container"]}>
        <img src={userAvatar} style={{ alignSelf: "end" }} />
        <h1 className={style["chat-message-bot"]}>{props.message}</h1>
      </div>
    );
  };

  const UserChatMessage = (props) => {
    return (
      <div className={style["chat-message-container"]}>
        <div className={style["chat-message-user"]}>{props.message}</div>
      </div>
    );
  };

  const ChatEmptyBody = (props) => {
    return (
      <div className={style["bot-section"]}>
        <h3
          className={style["header-text"]} >
          Customer Support Chat
        </h3>
        
        {props.isLoading ? (
          <div className={style["circular-progress"]}>
             <CircularProgress />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img
              src={customerBox}
              height="146"
              width="138"
              style={{ margin: "100px auto 0px auto" }}
            />
            <h3
              className={style["header-text"]}>
              No data selected
            </h3>
          </div>
        )}
      </div>
    );
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) {
      setError("Response message cannot be empty. Please enter a response.");
      return;
    }
    setSendMessageLoading(true);  
    try {
      await SubmitAnswerApi(selectedId, inputValue); 
      fetchFallBackData(); 
      setSelectedChatData((prevData) => ({
        ...prevData,
        response: inputValue,
      }));
      setInputValue(""); 
    } catch (error) {
      console.error("Error posting response:", error);
    } finally {
      setSendMessageLoading(false); 
    }
  };
  return (
    <>
    <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleCloses}
            message={message}
          />
      {error && <Snackbar open={true} autoHideDuration={3000} onClose={handleCloses} message={error} />}
      <Title title={"Customer Support Tickets"} />
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid size={7.5} sx={{ padding: "0px 0px 0px 35px" }}>
          {isLoadingFallback ? (
            <div className={style["loader-container"]}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <div
                  className={style["filter-icon-container"]}
                  onClick={handleAddClick}
                >
                  <img src={filter} />
                </div>
              </div>
              
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={filterUnanswered}>
                  {activeFilter === "unanswered" ? (
                    <CheckBox sx={{ marginRight: 1 }} />
                  ) : (
                    <CheckBoxOutlineBlank sx={{ marginRight: 1 }} />
                  )}
                  Unanswered
                </MenuItem>
 
                <MenuItem onClick={filterAnswered}>
                  {activeFilter === "answered" ? (
                    <CheckBox sx={{ marginRight: 1 }} />
                  ) : (
                    <CheckBoxOutlineBlank sx={{ marginRight: 1 }} />
                  )}
                  Answered
                </MenuItem>
              </Menu>
              <Tablecomponent
                rows={rowsData.map((row) => ({
                  ...row,
                  ticketId: row.ticketId.split(" ")[0],
                }))}
                columns={columns}
                onRowClick={handleRowClick}
                pageSizeOptions={[9]}
                initialPageSize={9}
                tableHeight={488}
                textColor="#C61E1E"
                headerBgColor="#FAF9F7"
              />
            </>
          )}
        </Grid>
        <Grid size={4.5}>
          {selectedTicketId === null ? (
            <ChatEmptyBody />
          ) : isLoadingChat ? (
            <ChatEmptyBody isLoading={isLoadingChat} />
          ) : (
            <div className={style["bot-section"]}>
              <div className={style["chat-header"]}>
                <ArrowBackIosIcon
                  sx={{ margin: "20px 0px 0px 10px" }}
                  fontSize="small"
                  onClick={hideChatBot}
                />
                <h3
                  className={style["header-text"]}
                  style={{ margin: "20px 0px 0px 0px" }}
                >
                  Ticket ID : {selectedTicketId}
                </h3>
                <h1></h1>
              </div>
 
              <div style={{ flex: "1", padding: "10px", overflowY: "auto" }}>
                {selectedChatData ? (
                  <>
                    <UserChatMessage message={selectedChatData.query} />
 
                    {selectedChatData.response ? (
                      <BotChatMessage message={selectedChatData.response} />
                    ) : (
                      <p></p>
                    )}
                  </>
                ) : (
                  <p>No chat data available</p>
                )}
              </div>
 
              {sendMessageLoading ? (
                <div className={style["circular-progress"]}>
                <CircularProgress/>
               </div>
              ) : selectedChatData && selectedChatData.response ? (
                <h1
                  style={{
                    fontSize: "15px",
                    color: "green",
                    textAlign: "center",
                  }}
                >
                  Response Submitted
                </h1>
              ) : (
                <>
                  <textarea
                    placeholder={"Type a Answer"}
                    value={inputValue}
                    onChange={handleInputChange}
                    className={style.input}
                    disabled={false}
                  />
 
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "8px",
                    }}
                  >
                    <button className={style["cancel-button"]}>Cancel</button>
                    <button
                      onClick={handleSubmit}
                      className={style["send-button"]}
                    >
                      Send
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default CustomerSupport;
