import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tablecomponent from '../../Components/Common/TableComponent/TableComponent';
import {CircularProgress} from "@mui/material";
import style from './UserVerification.module.css';
import { fetchUserCardData } from "../../api/api";
const UserVerification = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const columns = [
    { field: 'name', headerName: 'User Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email ID', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'cardNumber', headerName: 'Card Number', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'source', headerName: 'Source', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lastUpdate', headerName: 'Last Update', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'cardAssignedDate', headerName: 'Card Assigned date', flex: 1, headerClassName: 'super-app-theme--header' },
  ];

  const handleRowClick = (params) => {
    navigate(`/userVerification/${params.row.id}`);
  };


  useEffect(() => {
    const getUserCardData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserCardData();
        setUserData(data.data);
      } catch (error) {
        console.error("Error fetching user card data:", error);
      }
      finally{
        setLoading(false);
      }
    };

    getUserCardData();
  }, []);
  const rowss = userData ? userData.map(user => ({
    id: user.userid,
    name: user.username,
    email: user.email,
    cardNumber: user.cardNumber,
    source: user.cardType,
    lastUpdate: user.updatedAt,
    cardAssignedDate: user.createdAt,
  })) : [];

  return (
    <div>
      <>
        <h1 className={style.title}>User Verification</h1>
        {loading ? (
          <div className={style["circular-progress"]}>
            <CircularProgress />
          </div>
        ) : (
          <div className={style["table-style"]}>
            <Tablecomponent rows={rowss} columns={columns} onRowClick={handleRowClick} pageSizeOptions={[11]} initialPageSize={11} tableHeight={573} textColor="#404040" headerBgColor='#FAF9F7' />
          </div>
        )}
      </>
    </div>
  );
};

export default UserVerification;