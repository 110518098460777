// import React, { useState } from "react";
// import Grid from "@mui/material/Grid2";
// import style from "./UserDetails.module.css";
// import { CircularProgress, Modal, Box, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import TextVerifiedIcons from "../Components/TextVerifiedIcons/TextVerifiedIcons";
// import OutlinedButtonWithIcon from "../Components/OutlinedButtonwithIcon/OutlinedButtonWithIcon";
// import Delete from "../../../assets/delete.svg";
// import Reupload from "../../../assets/reupload.svg";
// import validate from "../../../assets/validate.svg";

// const DocumentWithImageText = (props) => {
//   console.log("props",props)
//   const [open, setOpen] = useState(false); 

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <Grid size={12}>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <TextVerifiedIcons title={props.text} isVerified={props.isVerified} />
//         <div className={style["image-container"]}>
//           {props.imageloading ? (
//             <CircularProgress />
//           ) : props.document ? (
//               <img
//                 src={props.document.imageUrl}
//                 alt="uploaded document"
//                 style={{ maxWidth: "100%", maxHeight: "300px", cursor: "pointer" }}
//                 onClick={handleOpen}
//               />
                       
//           ) : (
//             <div style={{ color: "gray", fontStyle: "italic" }}>
//               No document uploaded
//             </div>
//           )}
//         </div>

        
//         <Modal open={open} onClose={handleClose}>
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               p: 4,
//               outline: 0,
//               maxWidth: "90vw",
//               maxHeight: "90vh",
//               overflow: "auto",
//             }}
//           >
//             <IconButton
//               onClick={handleClose}
//               sx={{
//                 position: "absolute",
//                 top: 2,
//                 right: 8,
//                 color: "black",
//               }}
//             >
//               <CloseIcon />
//             </IconButton>

//             {props.document && (
//               <img
//                 src={props.document.imageUrl}
//                 alt="full-size document"
//                 style={{ maxWidth: "100%", maxHeight: "100vh" }}
//               />
//             )}
//           </Box>
//         </Modal>

//         <div className={style["button-container"]}>
//           <label
//             htmlFor="file-upload"
//             className="custom-file-upload"
//             style={{
//               border: "2px solid",
//               color: "#2467F5",
//               borderRadius: "16px",
//               display: "flex",
//               padding: "0px 10px",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <span>
//               <img src={Reupload} alt="Reupload" />
//             </span>
//             <span>{"Reupload"}</span>
//           </label>
//           <input
//             id="file-upload"
//             type="file"
//             onChange={(e) => props.handleFileChange(e, props.OnReupload)}
//             disabled={props.isUploading}
//             style={{ display: "none" }}
//           />
//           <OutlinedButtonWithIcon
//             text={"Delete"}
//             icon={Delete}
//             color="#B93E3E"
//             borderColor="#B93E3E"
//             className="button"
//             onClick={props.OnDelete}
//           />
//           <OutlinedButtonWithIcon
//             text={"Validate"}
//             icon={validate}
//             color="#1F8F1F"
//             borderColor="#1F8F1F"
//             className={`${style["third-button"]} button`}
//             onClick={props.OnValidate}
//           />
//         </div>
//       </div>
//     </Grid>
//   );
// };

// export default DocumentWithImageText;
import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import style from "./UserDetails.module.css";
import { CircularProgress, Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextVerifiedIcons from "../Components/TextVerifiedIcons/TextVerifiedIcons";
import OutlinedButtonWithIcon from "../Components/OutlinedButtonwithIcon/OutlinedButtonWithIcon";
import Delete from "../../../assets/delete.svg";
import Reupload from "../../../assets/reupload.svg";
import validate from "../../../assets/validate.svg";

const DocumentWithImageText = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderDocumentPreview = () => {
    if (props.imageloading) {
      return <CircularProgress />;
    }

    if (props.document) {
      if (props.document.documentData.type === "image/jpeg" || props.document.documentData.type === "image/png") {
        return (
          <img
            src={props.document.imageUrl}
            alt="uploaded document"
            style={{ maxWidth: "100%", maxHeight: "300px", cursor: "pointer" }}
            onClick={handleOpen}
          />
        );
      }

      if (props.document.documentData.type === "application/pdf") {
        return (
          <div>
            <iframe
              src={props.document.documentData.url}
              style={{ width: "400px", height: "400px", cursor: "pointer" }}
              title="PDF Preview"
              onClick={handleOpen}
            ></iframe>
            {/* <a
              href={props.document.documentData.url}
              download
              style={{ color: "#2467F5", textDecoration: "underline" }}
            >
              Download PDF
            </a> */}
          </div>
        );
      }
    }

    return (
      <div style={{ color: "gray", fontStyle: "italic" }}>
        No document uploaded
      </div>
    );
  };

  return (
    <Grid size={12}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextVerifiedIcons title={props.text} isVerified={props.isVerified} />
        <div className={style["image-container"]} style={{height:props?.document?.documentData?.type==="application/pdf"?"450px":"300px",width:props?.document?.documentData?.type==="application/pdf"?"500px":"450px"}}>{renderDocumentPreview()}</div>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: 0,
              maxWidth: "90vw",
              maxHeight: "90vh",
              overflow: "auto",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 2,
                right: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>

            {props.document?.documentData?.type === "image/jpeg" || props.document?.documentData?.type === "image/png" ? (
              <img
                src={props.document.imageUrl}
                alt="full-size document"
                style={{ maxWidth: "100%", maxHeight: "100vh" }}
              />
            ) : props.document?.documentData?.type === "application/pdf" ? (
              <iframe
                src={props.document.documentData?.url}
                style={{ width: "100%", height: "90vh" }}
                title="PDF Full View"
              ></iframe>
            ) : null}
          </Box>
        </Modal>

        <div className={style["button-container"]}>
          <label
            htmlFor="file-upload"
            className="custom-file-upload"
            style={{
              border: "2px solid",
              color: "#2467F5",
              borderRadius: "16px",
              display: "flex",
              padding: "0px 10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              <img src={Reupload} alt="Reupload" />
            </span>
            <span>{"Reupload"}</span>
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={(e) => props.handleFileChange(e, props.OnReupload)}
            disabled={props.isUploading}
            style={{ display: "none" }}
          />
          <OutlinedButtonWithIcon
            text={"Delete"}
            icon={Delete}
            color="#B93E3E"
            borderColor="#B93E3E"
            className="button"
            onClick={props.OnDelete}
          />
          <OutlinedButtonWithIcon
            text={"Validate"}
            icon={validate}
            color="#1F8F1F"
            borderColor="#1F8F1F"
            className={`${style["third-button"]} button`}
            onClick={props.OnValidate}
          />
        </div>
      </div>
    </Grid>
  );
};

export default DocumentWithImageText;
