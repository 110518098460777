import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import style from './DoughnutChart.module.css';
import { topTriggeredFAQs } from '../../../api/api';
import { ReactComponent as VectorIcon } from "../../../assets/Vector.svg"

Chart.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFaqData = async () => {
            try {
                const response = await topTriggeredFAQs(); 
                setFaqData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching FAQ data:', error);
                setLoading(false);
            }
        };
        fetchFaqData();
    }, []);

    const chartData = {
        labels: faqData.map(faq => faq.question),
        datasets: [
            {
                data: faqData.map(faq => faq.count),
                backgroundColor: ['#B48FFF', '#4BC0EB', '#66E0C0', '#FFE066', '#FF8574'],
                hoverBackgroundColor: ['#B48FFF', '#4BC0EB', '#66E0C0', '#FFE066', '#FF8574'],
                borderWidth: 0, 
                color: ['#F1ECFF', '#E6F3FF', '#EBFAFA', '#FFF3E6', '#D9FBE7']
            }
        ]
    };

    const options = {
        plugins: {
            legend: { display: false },        
        },
        cutout: '70%', 
    };

    const AnalyticsMiniCard = ({ question, count, color, icon }) => (
        <div className={style["faq-item"]}>
            <div className={style["faq-icon"]} style={{ backgroundColor: color,minWidth:"40px" }}>
                <VectorIcon style={{fill:icon}} alt="faq-icon" />
            </div>
            <div className={style["faq-details"]}>
                <div className={style["faq-question"]}>{question}</div>
                <div className={style["faq-count"]}>{count}</div>
            </div>
        </div>
    );

    const icons = [
        '#6B3DEC','#206EB7','#18B7B7','#EF8B1E','#19BB5B'
    ];

    return (
        <div className={style["analytics-container"]}>
            <div className={style["chart-section"]}>
                {loading ? (
                    <p>Loading data...</p>
                ) : (
                    <Doughnut data={chartData} options={options} />
                )}
            </div>
            <div className={style["faq-list"]}>
                {faqData.map((faq, index) => (
                    <AnalyticsMiniCard
                        key={faq.question}
                        question={faq.question}
                        count={faq.count}
                        color={chartData.datasets[0].color[index]}
                        icon={icons[index]}
                    />
                ))}
            </div>
        </div>
    );
};

export default DoughnutChart;
