import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../../../Components/Common/Title/Title";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AssignCard from "../AssignCard";
import {fetchOnboardingUserData, fetchUserCardDetails, onboardingHandleReject, saveOnboardingUserData, fileReuploadDoc, fileDeleteValidateDoc, postGetImage, userAiverify} from "../../../api/api";
import PersonalInformationStep from "./PersonalInformation";
import AddressInformationStep from "./AddressInformationStep";
import IdentificationDocumentStep from "./IdentificationDocumentStep";
import ButtonsSection from "./ButtonsSection"
import StepIndicator from "./StepIndicator";
import DocumentWithImageText from "./DocumentWithImageText"

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [open, setOpen] = useState(false);
  const [personalDocVerif, setPersonalDocVerif] = useState(false);
  const [addressDocVerif, setAddressDocVerif] = useState(false);
  const [idcpDocVerif, setIdcpDocVerif] = useState(false);
  const [userDataa, setUserDataa] = useState(null);
  const [idDoc, setIddoc] = useState(null);
  const [idAddressInfo, setIdAddressInfo] = useState(null);
  const [idcpDoc, setIdcpDoc] = useState(null);
  const [imageloading, setImageloading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [docLoading, setDocLoading] = useState({
    IdentifiactionDoc: true,
    AddressInfo: true,
    IDCPDoc: true,
  });
  const [formData, setFormData] = useState({
    personalInformation: {
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      // countryCode: "",
      number: "",
      dateOfBirth: "",
      countryOfBirth: "",
    },
    addressInformation: {
      addressLine: "",
      cityName: "",
      region: "",
      country: "",
      zipCode: "",
    },
    identificationDocument: {
      documentIssuedCountry: "",
      documentType: "",
      documentNumber: "",
    },
  });
  const [opendail, setOpendail] = useState(false);
  const [isPersonalInfoVerified, setIsPersonalInfoVerified] = useState(true);
  const [isAddressVerified, setIsAddressVerified] = useState(true);
  const [isDocumentInfoVerified, setIsDocumentInfoVerified] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [isCardAssigned, setIsCardAssigned] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [message, setMessage] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const steps = [
    "Personal Information",
    "Address Information",
    "Identification Documents",
  ];

  useEffect(() => {
    fetchUserData();
  }, [userId]);
 

  useEffect(() => {
    checkinfoVerificationStatus();
  }, [userDataa]);

  useEffect(() => {
    const getUserCardDetails = async () => {
      try {
        const data = await fetchUserCardDetails(userId);        
        setIsCardAssigned(data.status);
      } catch (error) {
        console.error("Error in fetching card details:", error.response?.data?.message || error.message);      
        setIsCardAssigned(error.response.data.status);        
      }
    };
    if (userId) {
      getUserCardDetails();
    }
  }, [userId]);

  const handleClose = () => {
    setOpendail(false);
  };
  const handleNext = async (next = "") => {
    try {
      const ContinueFunc = () => {       
        handleSave();        
        if (next === "next") {
          setCurrentStep((prevStep) =>
            Math.min(prevStep + 1, steps.length - 1)
          );
        } else {
          handleToastMessage("Data saved successfully!");
        }
      };
      if (currentStep === 0) {
        const personalValidation = validateSection(
          formData.personalInformation,
          personalInformationRules,
          "Personal Information"
        );
        if (personalValidation.status) {
          ContinueFunc();
        }
        handleToastMessage(personalValidation.message);
      } else if (currentStep === 1) {
        const addressValidation = validateSection(
          formData.addressInformation,
          addressInformationRules,
          "Address Information"
        );
        if (addressValidation.status) {
          ContinueFunc();
        }
        handleToastMessage(addressValidation.message);
      } else if (currentStep === 2) {
        const documentValidation = validateSection(
          formData.identificationDocument,
          identificationDocumentRules,
          "Identification Document"
        );
        if (documentValidation.status) {
          ContinueFunc();
        }
        handleToastMessage(documentValidation.message);
      }
    } catch (error) {
      handleToastMessage("An error occurred. Please try again.");
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const validateInput = (value, validationRules) => {
    if (validationRules.required && !value.trim()) {
      return "This field is required.";
    }
    if (validationRules.minLength && value.length < validationRules.minLength) {
      return `Minimum length is ${validationRules.minLength} characters.`;
    }
    if (validationRules.pattern && !validationRules.pattern.test(value)) {
      return "Invalid format.";
    }
    return "";
  };

  const validateSection = (sectionData, validationRules, sectionName) => {
    for (const field in validationRules) {
      const value = sectionData[field] || "";
      const error = validateInput(value, validationRules[field]);
      if (error) {
        return {
          status: false,
          message: `${sectionName}: ${field} - ${error}`,
        };
      }
    }
    return { status: true, message: `${sectionName} is valid.` };
  };

  const personalInformationRules = {
    firstName: { required: true, pattern: /^[a-zA-Z ]*$/ },
    middleName: { pattern: /^[a-zA-Z ]*$/ },
    lastName: { required: true, minLength: 2, pattern: /^[a-zA-Z ]*$/ },
    gender: { required: true, pattern: /^[a-zA-Z ]*$/ },
    //countryCode: { required: true, /*pattern: /^[+][0-9]{1,3}$/*/ },
    number: {required: true},
    dateOfBirth: { required: true },
    countryOfBirth: { pattern: /^[a-zA-Z ]*$/ },
  };

  const addressInformationRules = {
    addressLine: { required: true },
    cityName: { required: true, pattern: /^[a-zA-Z ]*$/ },
    region: { required: true, pattern: /^[a-zA-Z ]*$/ },
    country: { required: true, pattern: /^[a-zA-Z ]*$/ },
     zipCode: {required: false},
  };

  const identificationDocumentRules = {
    documentIssuedCountry: { required: true },
    documentType: { required: true },
    documentNumber: { required: true, minLength: 5 },
  };

const postGetIdentificationDocImage = async (data, imageType) => {
  setDocLoading((prev) => ({ ...prev, [imageType]: true }));
  await new Promise(resolve => setTimeout(resolve, 500));   

  if (imageType === "IdentifiactionDoc") {
    setIddoc(null);
  } else if (imageType === "AddressInfo") {
    setIdAddressInfo(null);
  } else {
    setIdcpDoc(null);
  }

  const ImageBlob = () => {
    if (imageType === "IdentifiactionDoc") {
      return data.personalInformation.url.value;
    } else if (imageType === "AddressInfo") {
      return data.addressInformation.url.value;
    } else {
      return data.identificationDocument.url.value;
    }
  };

  const requestBody = {
    blob: ImageBlob(),
  };
  
  try { 
      const response = await postGetImage(requestBody);
      const contentType = response.headers['content-type'];
    const isImage = contentType.includes('image/jpeg');
    const isPdf = contentType.includes('application/pdf');
      const imageBlob = response.data instanceof Blob 
        ? response.data 
        : new Blob([response.data], { type: contentType });
      const imageUrl = URL.createObjectURL(imageBlob);
      const filename =  `document-${Date.now()}.${isPdf ? 'pdf' : 'jpg'}`;
      const documentData = {
              url: imageUrl,
              type: contentType,
              blob: imageBlob,
              filename: filename
            };
      if (imageType === "IdentifiactionDoc") {
        setIddoc({imageUrl,documentData});
      } else if (imageType === "AddressInfo") {
        setIdAddressInfo({imageUrl,documentData});
      } else {
        setIdcpDoc({imageUrl,documentData});
      }

    } catch (error) {
      console.error(error)
    }finally{
      setDocLoading((prev) => ({ ...prev, [imageType]: false }));
    }
}

// const postGetIdentificationDocImage = async (data, imageType) => {
//   setDocLoading((prev) => ({ ...prev, [imageType]: true }));
//   await new Promise(resolve => setTimeout(resolve, 500));   

//   // Reset the respective state
//   if (imageType === "IdentifiactionDoc") {
//     setIddoc(null);
//   } else if (imageType === "AddressInfo") {
//     setIdAddressInfo(null);
//   } else {
//     setIdcpDoc(null);
//   }

//   const DocumentBlob = () => {
//     if (imageType === "IdentifiactionDoc") {
//       return data.personalInformation.url.value;
//     } else if (imageType === "AddressInfo") {
//       return data.addressInformation.url.value;
//     } else {
//       return data.identificationDocument.url.value;
//     }
//   };

//   const requestBody = {
//     blob: DocumentBlob(),
//   };
  
//   try { 
//     const response = await postGetImage(requestBody);
//     console.log("response", response);

//     // Determine the file type from the response
//     const contentType = response.headers['content-type'];
//     const isImage = contentType.includes('image/jpeg');
//     const isPdf = contentType.includes('application/pdf');

//     if (!isImage && !isPdf) {
//       throw new Error('Unsupported file type');
//     }

//     // Create blob with appropriate type
//     const documentBlob = response.data instanceof Blob 
//       ? response.data 
//       : new Blob([response.data], { type: contentType });

//     const documentUrl = URL.createObjectURL(documentBlob);

//     // Get filename from response headers or generate a default one
//     const filename =  `document-${Date.now()}.${isPdf ? 'pdf' : 'jpg'}`;

//     // Update the respective state with additional metadata
//     const documentData = {
//       url: documentUrl,
//       type: contentType,
//       blob: documentBlob,
//       filename: filename
//     };

//     if (imageType === "IdentifiactionDoc") {
//       setIddoc(documentData);
//     } else if (imageType === "AddressInfo") {
//       setIdAddressInfo(documentData);
//     } else {
//       setIdcpDoc(documentData);
//     }

//   } catch (error) {
//     console.error('Error processing document:', error);
//   } finally {
//     setDocLoading((prev) => ({ ...prev, [imageType]: false }));
//   }
// };

const handleDownload = (documentData) => {
  if (!documentData?.blob || !documentData?.filename) return;
  
  const downloadUrl = URL.createObjectURL(documentData.blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = documentData.filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(downloadUrl);
};



  const handleFileChange = async (event, underUpload) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      handleToastMessage("Invalid file type! Please upload a PNG, JPG, JPEG, or PDF file."); 
      return;     
    }    
    setTimeout(async () => {
      try {        
        if (selectedFile) {          
          await handleSubmit(selectedFile, underUpload);
        }
      } catch (error) {
        console.error(error)
      }
    }, 1000);
  };

  //handle file reupload
  const handleSubmit = async (file, uploadunder) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_upload_under", uploadunder);
    try {
      const response= await fileReuploadDoc(userId, formData); 
      if (response.status === 200) {
        handleToastMessage("File Uploaded");
        fetchUserData();
      }
    } catch (error) {
      handleToastMessage("File Upload Failed");
    } finally {
      setIsUploading(false); 
    }   
  };

  //handle delete
  const handleDelete = async (Type) => {
    const payload = {
      isvalid: false,
      isdeleted: true,
      document_upload_under: Type,
    };
    try{
      await fileDeleteValidateDoc(userId, payload);
      handleToastMessage("File Deleted");
      fetchUserData(true);
    }
    catch(error){
      handleToastMessage("File Delete Failed");
    }    
  };

  //File Validate
  const handleValidate = async (Type) => {
    const payload = {
      isvalid: true,
      isdeleted: false,
      document_upload_under: Type,
    };
    try{
      const response=await fileDeleteValidateDoc(userId, payload);
      handleToastMessage("File Validated");
        if (Type === "personalInformation") {
          setPersonalDocVerif(true);
        } else if (Type === "addressInformation") {
          setAddressDocVerif(true);
        } else if (Type === "identificationDocument") {
          setIdcpDocVerif(true);
        }
        checkinfoVerificationStatus();
    }
    catch(error){
      handleToastMessage("File Validation Failed");
    }     
  };

  function formatDate(inputValue) {
    const padWithZero = (value) => value.padStart(2, "0");
    if (inputValue.includes("-")) {
      return inputValue;
    }

    if (inputValue.includes(".")) {
      const [day, month, year] = inputValue.split(".");
      return `${year}-${padWithZero(month)}-${padWithZero(day)}`;
    }

    throw new Error("Invalid date format");
  }

  async function handleVerifyClick(){
    try{
      setIsVerifying(true);
      const response=await userAiverify(userId);
      if(response.data.status){
        handleToastMessage("AI Verification Success");       
      }
     fetchUserData();
     setIsVerifying(false);
    }
    catch(error){
      handleToastMessage(error.response.data.message);      
      setIsVerifying(false);
      fetchUserData();
    }     
  }

  //  fetch user Data
  const fetchUserData = async (xyz = false) => {
    try {
      const response=await fetchOnboardingUserData(userId);
      const data = response.data;
      setFormData({
        personalInformation: {
          firstName: data.personalInformation.firstName.value || "",
          middleName: data.personalInformation.middleName.value || "",
          lastName: data.personalInformation.lastName.value || "",
          gender: data.personalInformation.gender.value || "",
          //countryCode: data.personalInformation.countryCode.value || "",
          number: data.personalInformation.number.value || "",
          dateOfBirth: data.personalInformation.dateOfBirth.value || "",
          // dateOfBirth: formatDate(data.personalInformation.dateOfBirth.value) || "",
          countryOfBirth: data.personalInformation.countryOfBirth.value || "",
        },
        addressInformation: {
          addressLine: data.addressInformation.addressLine.value || "",
          cityName: data.addressInformation.cityName.value || "",
          region: data.addressInformation.region.value || "",
          country: data.addressInformation.country.value || "",
          zipCode: data.addressInformation.zipCode.value || "",
        },
        identificationDocument: {
          documentIssuedCountry:
            data.identificationDocument.documentIssuedCountry.value || "",
          documentType: data.identificationDocument.documentType.value || "",
          documentNumber:
            data.identificationDocument.documentNumber.value || "",
        },
      });
      setUserDataa(data);
      await Promise.all([
        postGetIdentificationDocImage(data, "IdentifiactionDoc"),
        postGetIdentificationDocImage(data, "AddressInfo"),
        postGetIdentificationDocImage(data, "IDCPDoc")
      ]);
      setPersonalDocVerif(data.personalInformation.url.status);
      setAddressDocVerif(data.addressInformation.url.status);
      setIdcpDocVerif(data.identificationDocument.url.status);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } 
  };

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleToastMessage = (messageText) => {
    setMessage(messageText);
    setOpen(true);
  };

  const action = <React.Fragment></React.Fragment>;

  // Handle Verification check and change the icons
  const checkinfoVerificationStatus = () => {
    if (!userDataa) return;

    let personalInfoVerified = true;
    let addressVerified = true;
    let documentInfoVerified = true;

    for (const [key, value] of Object.entries(userDataa.personalInformation)) {
      if (value["status"] !== undefined && value["status"] !== undefined) {
        if (value["status"] === false) {
          personalInfoVerified = false;
          break;
        }
      }
    }

    // Check address verification
    for (const [key, value] of Object.entries(
      userDataa.addressInformation || {}
    )) {
      if (value.status === false) {
        addressVerified = false;
        break;
      }
    }

    // Check document information verification
    for (const [key, value] of Object.entries(
      userDataa.identificationDocument || {}
    )) {
      if (value?.status === false) {
        documentInfoVerified = false;
        break;
      }
    }

    setIsPersonalInfoVerified(personalInfoVerified);
    setIsAddressVerified(addressVerified);
    setIsDocumentInfoVerified(documentInfoVerified);
  };

  const handleInputChange = (section, name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [name]: value,
      },
    }));
  };

  const handleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  // patch API work to update the specific field
  const patchUserData = async (updatedFieldPath, updatedValue) => {
    try {
      const payload = { [updatedFieldPath.split(".").pop()]: updatedValue };
      const response = await axios.patch(
        `http://localhost:8080/api/user/${userId}`,
        payload
      );
      if (response.status === 200) {
        // Update the relevant field in userDataa
        const updatedUserData = { ...userDataa };
        const fieldPathArray = updatedFieldPath.split(".");
        let current = updatedUserData;

        // Traverse to the relevant part of the object
        fieldPathArray.forEach((key, index) => {
          if (index === fieldPathArray.length - 1) {
            current[key] = {
              ...current[key],
              value: updatedValue,
              verified: 1,
            };
          } else {
            current[key] = { ...current[key] };
            current = current[key];
          }
        });
        setUserDataa(updatedUserData);

        checkinfoVerificationStatus();
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  if (!userDataa) {
    return <div>Loading...</div>;
  }

  const handleStepChange = (stepIndex) => {
    if (stepIndex === 3) {
      handleToastMessage("You cannot assign a card without approval")
      return; 
    }
    if (isEditing) {
      setCurrentStep(stepIndex);
    } else {
      handleToastMessage("Please Submit or Close the Active text Field");
    }
  };

  // Save
  const handleSave = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
      try {
       const response= await saveOnboardingUserData(userId, formData);
      } catch (error) {
        console.error("Error submitting data:", error);
      }
  };

  const handleAppRej = (status) => {
    handleSave();
    handleSubmitReject(status);
  };
  
  

  
  const handleSubmitReject = async (status) => {
    try {
      const response = await onboardingHandleReject(userId, status);
      if (response.status && status === "Approve" && isCardAssigned === false) {
        setCurrentStep(3);
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div style={{ margin: "0px 30px" }}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleCloses}
        message={message}
        action={action}
      />

      <React.Fragment>
        <Dialog
          open={opendail}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              handleClose();
            },
          }}
        >
          <DialogTitle>Card</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ width: "auto" }}>
              please Enter the Card number.
            </DialogContentText>
            <TextField
              sx={{ width: "300px" }}
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              label="Card Number"
              type="email"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Cancel</Button> */}
            <Button type="submit">Submit</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0px 30px" }}>
        <Title title="User Onboarding" />
        <Button
          variant="contained" 
          disabled={isVerifying}
          onClick={handleVerifyClick}         
          style={{ width: "100px",  backgroundColor:"#2466F4" , height: "40px"}}>          
          {isVerifying ? "Verifying..." : "Verify"}
        </Button>
      </div>
      <div
        style={{
          borderRadius: "8px",
          padding: "0px 30px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <StepIndicator 
            currentStep={currentStep}
            isPersonalInfoVerified={isPersonalInfoVerified}
            isAddressVerified={isAddressVerified}
            isDocumentInfoVerified={isDocumentInfoVerified}
            isCardAssigned={isCardAssigned} 
            onStepChange={handleStepChange}           
          />
          <Grid container spacing={1}>
            <Grid size={currentStep === 3 ? 12 : 6.5}>
              <Grid container spacing={currentStep !== 3 ? 4 : undefined}>
                {currentStep === 0 && (
                  <PersonalInformationStep formData={formData}
                  userDataa={userDataa}
                  handleInputChange={handleInputChange}
                  handleIsEditing={handleIsEditing}
                  handleToastMessage={handleToastMessage}
                  patchUserData={patchUserData}/>
                )}
                {currentStep === 1 && (
                  <AddressInformationStep formData={formData}
                  userDataa={userDataa}
                  handleInputChange={handleInputChange}
                  handleIsEditing={handleIsEditing}
                  handleToastMessage={handleToastMessage}
                  patchUserData={patchUserData}/>
                )}

                {currentStep === 2 && (
                  <IdentificationDocumentStep
                  formData={formData}
                  userDataa={userDataa}
                  handleInputChange={handleInputChange}
                  handleIsEditing={handleIsEditing}
                  handleToastMessage={handleToastMessage}
                  patchUserData={patchUserData}
                  />
                )}
                {currentStep === 3 && <AssignCard userId={userId} />}
                <ButtonsSection currentStep={currentStep}
                  steps={steps}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleAppRej={handleAppRej}/>
              </Grid>
            </Grid>
            {currentStep !== 3 && (
              <Grid size={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            )}
            {currentStep !== 3 && (
              <Grid size={5}>
                {currentStep === 0 && (
                  <DocumentWithImageText
                    isVerified={personalDocVerif}
                    text="Identification Document"
                    document={idDoc}
                    OnReupload={"personalInformation"}
                    OnDelete={() => handleDelete("personalInformation")}
                    OnValidate={() => handleValidate("personalInformation")}
                    imageloading={docLoading.IdentifiactionDoc}
                    isUploading={isUploading}
                    handleFileChange={handleFileChange}
                  />
                )}
                {currentStep === 1 && (
                  <DocumentWithImageText
                    isVerified={addressDocVerif}
                    text="Address Information"
                    document={idAddressInfo}
                    OnReupload={"addressInformation"}
                    OnDelete={() => handleDelete("addressInformation")}
                    OnValidate={() => handleValidate("addressInformation")}
                    imageloading={docLoading.AddressInfo}
                    isUploading={isUploading}
                    handleFileChange={handleFileChange}
                  />
                )}
                {currentStep === 2 && (
                  <DocumentWithImageText
                    isVerified={idcpDocVerif}
                    text="IDCP Document"
                    document={idcpDoc}
                    OnReupload={"identificationDocument"}
                    OnDelete={() => handleDelete("identificationDocument")}
                    OnValidate={() => handleValidate("identificationDocument")}
                    imageloading={docLoading.IDCPDoc}
                    isUploading={isUploading}
                    handleFileChange={handleFileChange}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default UserDetailsPage;