import React, { useState } from "react";
import styles from "./Header.module.css";
import userAvatar from "../../../assets/userAvatar.svg";
import { useNavigate } from "react-router-dom";

const Header = ({
  username,
  profileImage,
  setIsAuthenticated,
  setUsername,
}) => {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("username");

    setIsAuthenticated(false);
    setUsername("");
    setShowLogoutPopup(false);
    navigate("/");
  };

  const toggleLogoutPopup = () => {
    setShowLogoutPopup(!showLogoutPopup);
  };

  return (
    <div className={styles.topbar}>
      <div className={styles.topbarLeft}>
        <img className={styles.photo} src={userAvatar} alt="head_Img" />
      </div>
      <div className={styles.topbarRight}>
        <span className={styles.username}>{username}</span>
        <div onClick={toggleLogoutPopup} className={styles.profileContainer}>
          <img
            src={profileImage}
            alt="Profile"
            className={styles.profilePhoto}
          />
          {showLogoutPopup && (
            <div className={styles.logoutPopup}>
              <button onClick={handleLogout} className={styles.logoutButton}>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;