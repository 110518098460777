import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import emptycard from '../../../assets/emptyCard.png';
import { fetchUserCardDetails } from '../../../api/api';

const UserVerificationDetails = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true); 
  const [userDataa, setUserDataa] = useState(null);

  useEffect(() => {
    const getCardData = async() => {
      try{
        const response = await fetchUserCardDetails(userId)
        setUserDataa(response.data);
          setLoading(false); 
      }catch(error){
        console.error('Error fetching card details:', error);
        setLoading(false); 
      }
    }
    getCardData()
  }, [userId]); 

  return (
    <div style={styles.outerContainer}>
      <div style={styles.topBar}>
        <h2 style={styles.header3}>User Details</h2>
      </div>
      <div style={styles.detailsContainer}>
        {userDataa ? (
          <>
            <div style={styles.inlineField}>
              <p><strong>Name :</strong></p>
              <div style={styles.valueBox}>{userDataa.username}</div>
            </div>
            <div style={styles.inlineField}>
              <p><strong>Card number :</strong></p>
              <div style={styles.valueBox}>{userDataa.cardNumber}</div>
            </div>
            <div style={styles.inlineField}>
              <p><strong>Approval Date :</strong></p>
              <div style={styles.valueBox}>{userDataa.assignDate}</div>
            </div>
            <div style={styles.inlineField}>
              <p><strong>Approval By :</strong></p>
              <div style={styles.valueBox}>{userDataa.approvalBy}</div>
            </div>
            <div style={styles.inlineField}>
              <p><strong>Assigned Date :</strong></p>
              <div style={styles.valueBox}>{userDataa.assignDate}</div>
            </div>
          </>
        ) : (
          <p>No user data available.</p>
        )}
      </div>

      <div style={styles.loadingContainer}>
        {loading ? (
          <>
            <CircularProgress />
            <p>Loading data...</p>
          </>
        ) : (
          <div style={styles.cardImageContainer}>
            <img src={emptycard} alt="Card" style={styles.cardImage} />
            {userDataa && userDataa.cardNumber && (
              <div>
                <div style={styles.username}>
                {userDataa.username}
                </div>
                <div style={styles.cardNumber}>
                {userDataa.cardNumber}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  outerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '565px',
    padding: '20px',
    position: 'relative',
  },
  topBar: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  header3: {
    fontSize: '20px',
    margin: 0,
    textAlign: 'left',
    fontWeight: '700',
    color: '#4E4E4E',
    
  },
  detailsContainer: {
    flex: 1,
    marginLeft: '70px',
    marginRight: '20px',
    padding: '24px',
    maxWidth: '35%',
    gap: '24px',
    border: '1px solid #D7D7D7',
    borderRadius: '8px',
  },
  inlineField: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: 'auto',
    height: '50px',
    fontWeight: '700',
    color: '#4E4E4E',
  },
  valueBox: {
    border: '1px solid #D7D7D7',
    borderRadius: '8px',
    textAlign: 'center',
    height: '20px',
    minWidth: '65px',
    fontWeight: '700',
    color: '#4E4E4E', 
    padding: '8px 12px 8px 12px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    borderRadius: '10px',
    padding: '20px',
  },
  cardImageContainer: {
    position: 'relative', 
    width: '100%',
    maxWidth: '400px',
  },
  cardImage: {
    width: '100%',
    maxWidth: '400px',
  },
  username: {
    position: 'absolute',
    top: '15%', 
    left: '33%', 
    transform: 'translate(-50%, -50%)', 
    color: '#6C7CDF', 
    fontSize: '24px',
    fontWeight: '600',
    overflow: 'hidden',
  },
  cardNumber: {
    position: 'absolute',
    top: '85%', 
    left: '34%', 
    transform: 'translate(-50%, -50%)', 
    color: '#fff', 
    fontSize: '24px', 
    fontWeight: '700',
  },
};

export default UserVerificationDetails;
