import styles from './TextVerifiedIcons.module.css';
import {FiCheck } from "react-icons/fi";
import { Cross } from "akar-icons";

const TextVerifiedIcons=(props)=>{
    return(
        <h1 className={styles.title}>
        {props.title}
        {props.isVerified ? (
          <FiCheck
            className={styles["verified-icon"]}
            strokeWidth={3}
            size={8}
            color="#008000"
          />
        ) : (
          <Cross
            className={styles["not-verified-icon"]}
            strokeWidth={5}
            size={8}
            color="#A73333"
          />
        )}
      </h1>
    );
}
export default TextVerifiedIcons;