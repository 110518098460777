export const API_BASE_URL = window.location.origin + "/api/be-bot/" //for deployement
export const getAnalyticsApi = API_BASE_URL+"analytics"
export const getTopTriggeredFAQs = API_BASE_URL+"faq/dashboard/count"
export const getAccordianDataApi = API_BASE_URL+"faq/dashboard/display"
export const deleteFaqApi = API_BASE_URL+"faq/delete/"
export const updateFaqApi = API_BASE_URL+"faq/update/"
export const addFaqApi = API_BASE_URL+"faq/add"
export const faqUploadApi = API_BASE_URL+"faq/upload"
export const onBoardingApi = API_BASE_URL+"onboarding"
export const userCardApi = API_BASE_URL+"usercard"
export const fallBackDataApi = API_BASE_URL+"fallbacks"
export const userImageApi = API_BASE_URL+"onboarding/image"
export const userValidateApi = API_BASE_URL+"onboarding/status"
export const fileReupload = API_BASE_URL+"onboarding/update/document"
export const fileDeleteValidate = API_BASE_URL+"onboarding/document/validate"
export const aiVerificationStatus= API_BASE_URL+"verify/users"
export const userAIVerification= API_BASE_URL+"verify/user"