import React from 'react';
import Grid from "@mui/material/Grid2";
import CustomInputField from "../../../Components/CustomInputField/CustomInputField";

const AddressInformationStep = ({ 
  formData, 
  userDataa, 
  handleInputChange, 
  handleIsEditing, 
  handleToastMessage, 
  patchUserData 
}) => {
  return (
    <>
      <Grid size={12}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isVerified={userDataa.addressInformation.addressLine?.status}
          title="Address Line"
          onChange={(name, value) =>
            handleInputChange("addressInformation", "addressLine", value)
          }
          name={"addressLine"}
          value={formData.addressInformation.addressLine}
          multiline
          onSubmit={(updatedValue) =>
            patchUserData("addressInformation.addressLine", updatedValue)
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange("addressInformation", "cityName", value)
          }
          name={"cityName"}
          isEdittingSubmitted={true}
          isVerified={userDataa.addressInformation.cityName?.status}
          title="City Name"
          value={formData.addressInformation.cityName}
          onSubmit={(updatedValue) =>
            patchUserData("addressInformation.cityName", updatedValue)
          }
          validationRules={{
            required: true,
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange("addressInformation", "region", value)
          }
          name={"region"}
          isEdittingSubmitted={true}
          isVerified={userDataa.addressInformation.region?.status}
          title="Region"
          value={formData.addressInformation.region}
          onSubmit={(updatedValue) =>
            patchUserData("addressInformation.region", updatedValue)
          }
          validationRules={{
            required: true,
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange("addressInformation", "country", value)
          }
          name={"country"}
          isEdittingSubmitted={true}
          isVerified={userDataa.addressInformation.country?.status}
          title="Country"
          value={formData.addressInformation.country}
          onSubmit={(updatedValue) =>
            patchUserData("addressInformation.country", updatedValue)
          }
          validationRules={{
            required: true,
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isVerified={userDataa.addressInformation.zipCode?.status}
          onChange={(name, value) =>
            handleInputChange("addressInformation", "zipCode", value)
          }
          name={"zipCode"}
          title="Zip Code (optional)"
          value={formData.addressInformation.zipCode}
          onSubmit={(updatedValue) =>
            patchUserData("addressInformation.zipCode", updatedValue)
          }
          validationRules={{
            required: false,
          }}
        />
      </Grid>
    </>
  );
};

export default AddressInformationStep;