import React from 'react';
import Grid from "@mui/material/Grid2";
import CustomInputField from "../../../Components/CustomInputField/CustomInputField";

const PersonalInformationStep = ({ 
  formData, 
  userDataa, 
  handleInputChange, 
  handleIsEditing, 
  handleToastMessage, 
  patchUserData 
}) => {
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  return (
    <>
      <Grid size={4}>
        <CustomInputField
          isVerified={userDataa.personalInformation.firstName?.status}
          title="First Name"
          onChange={(name, value) =>
            handleInputChange("personalInformation", "firstName", value)
          }
          name={"firstName"}
          value={formData.personalInformation.firstName}
          validationRules={{
            required: true,
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
      <Grid size={4}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange("personalInformation", "middleName", value)
          }
          name={"middleName"}
          isEdittingSubmitted={true}
          isVerified={userDataa.personalInformation.middleName?.status}
          title="Middle Name"
          value={formData.personalInformation.middleName}
          onSubmit={(updatedValue) =>
            patchUserData("personal_information.middle_name", updatedValue)
          }
          validationRules={{
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
      <Grid size={4}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange("personalInformation", "lastName", value)
          }
          name={"lastName"}
          isEdittingSubmitted={true}
          isVerified={userDataa.personalInformation.lastName?.status}
          title="Last Name"
          value={formData.personalInformation.lastName}
          onSubmit={(updatedValue) =>
            patchUserData("personal_information.last_name", updatedValue)
          }
          validationRules={{
            required: true,
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isVerified={userDataa.personalInformation.gender?.status}
          onChange={(name, value) =>
            handleInputChange("personalInformation", "gender", value)
          }
          name={"gender"}
          isGender={true}
          title="Gender"
          value={formData.personalInformation.gender}
          options={genderOptions}
          onSubmit={(updatedValue) =>
            patchUserData("personal_information.gender", updatedValue)
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          isDisabled={false}
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isVerified={userDataa.personalInformation.number?.status}
          onChange={(name, value) =>
            handleInputChange("personalInformation", "number", value)
          }
          name={"number"}
          title="Phone Number"
          isPhoneField={true}
          value={formData.personalInformation.number}
          onSubmit={(updatedValue) =>
            patchUserData("personal_information.number", updatedValue)
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isDateField={true}
          onChange={(name, value) =>
            handleInputChange("personalInformation", "dateOfBirth", value)
          }
          name={"dateOfBirth"}
          isVerified={userDataa.personalInformation.dateOfBirth?.status}
          title="Date of Birth"
          value={formData.personalInformation.dateOfBirth}
          onSubmit={(updatedValue) =>
            patchUserData("personal_information.date_of_birth", updatedValue)
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange("personalInformation", "countryOfBirth", value)
          }
          name={"countryOfBirth"}
          isEdittingSubmitted={true}
          isVerified={userDataa.personalInformation.countryOfBirth?.status}
          title="Country of Birth (optional)"
          value={formData.personalInformation.countryOfBirth}
          onSubmit={(updatedValue) =>
            patchUserData("personal_information.country_of_birth", updatedValue)
          }
          validationRules={{
            required: false,
            pattern: /^[a-zA-Z ]*$/,
          }}
        />
      </Grid>
    </>
  );
};

export default PersonalInformationStep;