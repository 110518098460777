import React from 'react';

const OutlinedButtonWithIcon = (props) => {
  const { text, icon, color = '#6200EE', borderColor = '#6200EE', onClick, style } = props;

  const defaultStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
    border: `2px solid ${borderColor}`,
    borderRadius: '16px',
    backgroundColor: 'transparent',
    color: color,
    cursor: 'pointer',
    width:'140px',
    fontSize: '16px',
    fontweight:'500',
    gap: '8px', 
    ...style 
  };

  return (
    <button
      style={defaultStyle}
      onClick={onClick}
    >
      {icon && <span><img src={icon} color='red'/> </span>}
      <span>{text}</span>
    </button>
  );
};

export default OutlinedButtonWithIcon;
