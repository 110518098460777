import React from 'react';
import styles from './CircleWithText.module.css'; // Correct CSS Modules import

const CircleWithText = ({ text, color = '#3498db', img, callBackFuntion, isActive}) => {
  const textLines = text.split('<br/>');

  return (
    <div className={styles.circleWithText} onClick={callBackFuntion}>
      <div
        className={styles.circle}
        style={{
          backgroundColor: color,
        }}
      >
        <img src={img} alt="step-icon"/>
      </div>
      <div className={`${styles.circleText} ${isActive ? styles.underline : ''}`}>
      {/* <div className={styles.circleText}> */}
        {textLines.map((line, index) => (
          <p key={index}>
            {line}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CircleWithText;

