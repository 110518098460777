import React from "react";
import { DataGrid} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import style from './TableComponent.module.css';

const Tablecomponent = ({
  rows = [],
  columns = [],
  pageSizeOptions = [10],
  initialPageSize = 10,
  checkboxSelection = false,
  onRowClick = null,
  sx = {},
  tableHeight = 530,
  textColor = "black",
  headerBgColor = "#D3D3D3",
  apiRef,
  ...otherProps
}) => {
  const handleRowClick = (params) => {
    if (onRowClick) {
      onRowClick(params);
    }
  };

  return (
    <Box sx={{
      height: tableHeight,
      width: '100%',
      '& .super-app-theme--header': {
        background: headerBgColor
      },
    }}>
      <DataGrid
        apiRef={apiRef}
        editMode="false"
        columnHeaderHeight={56}
        rowHeight={42}
        rows={rows}
        columns={columns}
        scrollbarSize={0}
        disableColumnResize
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: initialPageSize } },
        }}
        pageSizeOptions={pageSizeOptions}
        checkboxSelection={checkboxSelection}
        onRowClick={handleRowClick}
        getRowId={(row) => `${row.userName}-${row.emailId}-${row.id}`}
        getRowClassName={(params) => {
          if (params.row.status === true) {
            return style.greenText;
          } else if (params.row.status === false) {
            return style.redText;
          } else {
            return style.defaultText;
          }
        }}
        {...otherProps}
      />
    </Box>
  );
};

export default Tablecomponent;
