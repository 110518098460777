import axiosInstance from "./axiosInstance";
import { getAnalyticsApi, getTopTriggeredFAQs, getAccordianDataApi, deleteFaqApi, updateFaqApi, addFaqApi, faqUploadApi, onBoardingApi, userCardApi, fallBackDataApi, userValidateApi, fileReupload, fileDeleteValidate, userImageApi, aiVerificationStatus, userAIVerification } from "./config";

const topTriggeredFAQs = async () => {
  try {
    const response = await axiosInstance.get(getTopTriggeredFAQs)
    return response;
  } catch (error) {
    console.error("error fetching Top Triggered FAQ's", error)
  }
}
const fetchAnalyticsData = async () => {
  try {
    const response = await axiosInstance.get(getAnalyticsApi)
    return response;
  } catch (error) {
    console.error("error fetching analytics", error)
  }
}

const fetchAccordianData = async () => {
  try {
    const response = await axiosInstance.get(getAccordianDataApi, {
      headers: {
        'Content-Type': 'application/json',
        'Custom-Header': 'value',
      },
    });
    return response;
  } catch (err) {
    console.error("Error fetching accordion data:", err.response?.data || err.message);
  }
}

const deleteFaq = async (faqId) => {
  try {
    const response = await axiosInstance.delete(`${deleteFaqApi}/${faqId}`);
    return response;
  } catch (error) {
    console.error("Error deleting FAQ:", error);
    throw error;
  }
};

const updateFaq = async (faqId, question, answer) => {
  try {
    const response = await axiosInstance.put(`${updateFaqApi}/${faqId}`, {
      question,
      answer,
    });
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};

const addFaq = async (question, answer) => {
  try {
    const response = await axiosInstance.post(addFaqApi, {
      question,
      answer,
    });
    return response;
  } catch (error) {
    console.error("Error adding FAQ:", error);
    throw error;
  }
};

const uploadFaqFile = async (file, uploaderName) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('uploader_name', uploaderName);
  try {
    const response = await axiosInstance.post(faqUploadApi, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error("Error uploading FAQ file:", error);
    throw error;
  }
};

const fetchOnboardingData = async () => {
  try {
    const response = await axiosInstance.get(onBoardingApi, {
      headers: {
        'Content-Type': 'application/json',
        'Custom-Header': 'value',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching onboarding data:", error);
    throw error;
  }
};

const fetchUserCardData = async () => {
  try {
    const response = await axiosInstance.get(userCardApi, {
      headers: {
        'Content-Type': 'application/json',
        'Custom-Header': 'value',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user card data:", error);
    throw error;
  }
};

const fetchFallBackDataApi = async (activeFilter) => {
  try {
    let endpoint = fallBackDataApi;
    if (activeFilter === "answered") {
      endpoint += "?success=true";
    } else if (activeFilter === "unanswered") {
      endpoint += "?success=false";
    }
    const response = await axiosInstance.get(endpoint);


    // const formattedData = response.data.data.reverse().map((row) => {
    //   const date = new Date(row.updatedAt);

    //   const formattedDate = date.toLocaleDateString("en-GB", {
    //     day: "2-digit",
    //     month: "short",
    //     year: "numeric",
    //   });
      
    //   return { ...row, updatedAt: formattedDate };
    // });
    const formattedData = response.data.data.reverse().map(({ updatedAt, ...row }) => {
      const [monthStr, day, year, time] = updatedAt.split(/[\s/]+/);
      
      // Directly parse the date string with month abbreviation
      const date = new Date(`${monthStr} ${day}, ${year} ${time}`);
      
      if (isNaN(date)) {
        console.error("Invalid date:", updatedAt);
        return row;
      }
      
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      
      return { ...row, updatedAt: formattedDate };
    });
    
    
    


    return formattedData;
  } catch (error) {
    console.error("Error fetching fallback data:", error);
    throw error;
  }
};

const fetchTicketChatDataApi = async (selectedId) => {
  try {
    const response = await axiosInstance.get(`${fallBackDataApi}/${selectedId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching ticket chat data:", error);
    throw error;
  }
};

const SubmitAnswerApi = async (selectedId, message) => {
  try {
    const response = await axiosInstance.post(`${fallBackDataApi}/${selectedId}`, {
      message,
    });
    return response.data;
  } catch (error) {
    console.error("Error posting response:", error);
    throw error;
  }
};

const uploadUserCardApi = async (cardNumber, userId) => {
  try {
    const response = await axiosInstance.post(userCardApi, {
      cardNumber,
      userid: userId,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading user card:", error.response?.data?.message || error.message);
    throw error;
  }
};

const fetchUserCardDetails = async (userId) => {
  try {
    const response = await axiosInstance.get(`${userCardApi}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching card details:", error.response?.data?.message || error.message);
    throw error;
  }
};

const onboardingHandleReject = async (userId, status) => {
  try {
    const response = await axiosInstance.put(`${userValidateApi}/${userId}`, {
      status: status,
    });
    return response;
  }
  catch (error) {
    console.error("Error in validating user", error);
  }
}

const saveOnboardingUserData = async (userId, formData) => {
  try {
    const response = await axiosInstance.put(`${onBoardingApi}/${userId}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error saving Onboarding user data:", error);
    throw error;
  }
};

const fileReuploadDoc = async (userId, formData) => {
  try {
    const response = await axiosInstance.put(`${fileReupload}/${userId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    return response;
  } catch (error) {
    console.error("Error in File Uploading:", error);
    throw error;
  }
};

const fileDeleteValidateDoc = async (userId, payload) => {
  try {
    const response = await axiosInstance.put(`${fileDeleteValidate}/${userId}`,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error deleting/validating data:", error);
    throw error;
  }
};

const fetchOnboardingUserData = async (userId) => {
  try {
    const response = await axiosInstance.get(`${onBoardingApi}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching onboarding data:", error);
    throw error;
  }
};

const postGetImage = async (body) => {
  try {
    const response = await axiosInstance.post(`${userImageApi}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.error("Error posting image:", error);
    throw error;
  }
};

const fetchAIVerificationStatus = async () => {
  try {
    const response = await axiosInstance.patch(aiVerificationStatus, { 
       responseType: "stream"  
    });
    const reader = response.body.getReader();      
    return reader;
  } catch (error) {
    console.error("Error getting AI Verification Status:", error);
    throw error;
  }
};

const userAiverify=async(userId)=>{
try{
const response = await axiosInstance.patch(`${userAIVerification}/${userId}`);
 return response;
}
catch (error) {
 console.error("Error in AI User Verification Failed:", error);
 throw error;
}
}
export { fetchAnalyticsData, topTriggeredFAQs, fetchAccordianData, deleteFaq, updateFaq, addFaq, uploadFaqFile, fetchOnboardingData, fetchUserCardData, fetchFallBackDataApi, fetchTicketChatDataApi, SubmitAnswerApi, uploadUserCardApi, fetchUserCardDetails, onboardingHandleReject, saveOnboardingUserData, fileReuploadDoc, fileDeleteValidateDoc, postGetImage, fetchOnboardingUserData, fetchAIVerificationStatus, userAiverify}