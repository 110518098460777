import React from 'react';
import Grid from "@mui/material/Grid2";
import CustomInputField from "../../../Components/CustomInputField/CustomInputField";

const IdentificationDocumentStep = ({ 
  formData, 
  userDataa, 
  handleInputChange, 
  handleIsEditing, 
  handleToastMessage, 
  patchUserData 
}) => {
  return (
    <>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isVerified={
            userDataa.identificationDocument.documentIssuedCountry?.status
          }
          title="Document Country of Issue"
          value={formData.identificationDocument.documentIssuedCountry}
          name={"documentIssuedCountry"}
          onChange={(name, value) =>
            handleInputChange(
              "identificationDocument",
              "documentIssuedCountry",
              value
            )
          }
          onSubmit={(updatedValue) =>
            patchUserData(
              "identificationDocument.documentIssuedCountry",
              updatedValue
            )
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
      <Grid size={6}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          isEdittingSubmitted={true}
          isVerified={userDataa.identificationDocument.documentType?.status}
          onChange={(name, value) =>
            handleInputChange(
              "identificationDocument",
              "documentType",
              value
            )
          }
          name={"documentType"}
          title="Document Type"
          value={formData.identificationDocument.documentType}
          onSubmit={(updatedValue) =>
            patchUserData(
              "identificationDocument.documentType",
              updatedValue
            )
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
      <Grid size={12}>
        <CustomInputField
          onIsEditting={() => handleIsEditing()}
          handleToastMessage={handleToastMessage}
          onChange={(name, value) =>
            handleInputChange(
              "identificationDocument",
              "documentNumber",
              value
            )
          }
          name={"documentNumber"}
          isEdittingSubmitted={true}
          isVerified={
            userDataa.identificationDocument.documentNumber?.status
          }
          title="Document Number"
          value={formData.identificationDocument.documentNumber}
          onSubmit={(updatedValue) =>
            patchUserData(
              "identificationDocument.documentNumber",
              updatedValue
            )
          }
          validationRules={{
            required: true,
          }}
        />
      </Grid>
    </>
  );
};

export default IdentificationDocumentStep;