import Grid from "@mui/material/Grid2";
import style from "./UserDetails.module.css";

const ButtonsSection = ({currentStep,
    steps,
    handleBack,
    handleNext,
    handleAppRej}) => {
    return (
      <Grid size={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {currentStep > 0 && currentStep !== 3 && (
            <button onClick={handleBack} className={style["back-button"]}>
              Back
            </button>
          )}

          {currentStep < steps.length - 1 && (
            <>
              <button
                onClick={() => handleNext()}
                className={style["back-button"]}
              >
                Save
              </button>
              <button
                onClick={() => handleNext("next")}
                className={style["approve-button"]}
              >
                Save & Next
              </button>
            </>
          )}

          {currentStep === 2 && (
            <>
              <button
                onClick={() => handleNext()}
                className={style["back-button"]}
              >
                Save
              </button>
              <button
                className={style["reject-button"]}
                onClick={() => handleAppRej("Reject")}
              >
                Reject
              </button>

              <button
                className={style["approve-button"]}
                onClick={() => handleAppRej("Approve")}
              >
                Approve
              </button>
            </>
          )}
        </div>
      </Grid>
    );
  };
  export default ButtonsSection