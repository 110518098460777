import React, { useState, useEffect } from "react";
import styles from "./CustomInputField.module.css";
import TextVerifiedIcons from "../../Pages/UserOnboarding/Components/TextVerifiedIcons/TextVerifiedIcons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import styled from "styled-components";

const WrapperStyleDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 325px;
  }
`;

const CustomInputField = ({
  isDisabled = false,
  title,
  value,
  onChange,
  multiline,
  placeholder,
  name,
  isVerified = false,
  isDateField = false,
  isGender = false,
  isPhoneField = false,
  options = [],
  validationRules = {},
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const validateInput = (value) => {
    if (validationRules.required && !value?.trim()) {
      return "This field is required.";
    }
    if (validationRules.minLength && value.length < validationRules.minLength) {
      return `Minimum length is ${validationRules.minLength} characters.`;
    }
    if (validationRules.pattern && !validationRules.pattern.test(value)) {
      return "Invalid format.";
    }
    return "";
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (onChange) {
      onChange(name, newValue);
    }

    const validationError = validateInput(newValue);
    setErrorMessage(validationError);
  };

  const handlePhoneChange = (newPhoneValue) => {
    setInputValue(newPhoneValue);
    if (onChange) {
      onChange(name, newPhoneValue);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "MM-dd-yyyy");
      setInputValue(formattedDate);

      if (onChange) {
        onChange(name, formattedDate);
      }
    } else {
      setInputValue("");
      if (onChange) {
        onChange(name, "");
      }
    }
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    try {
      if (dateString instanceof Date) return dateString;
      let parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
      if (isNaN(parsedDate.getTime())) {
        parsedDate = new Date(dateString);
      }
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  };

  return (
    <div className={styles.customInput}>
      <TextVerifiedIcons title={title} isVerified={isVerified} />
      <div className={styles.inputContainer}>
        {multiline ? (
          <textarea
            placeholder={placeholder || "Enter text"}
            value={inputValue}
            onChange={handleInputChange}
            className={styles.input}
          />
        ) : isGender ? (
          <select
            value={inputValue}
            onChange={handleInputChange}
            className={styles.input}
          >
            {options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                selected={
                  option.value.toLowerCase() === inputValue?.toLowerCase()
                }
              >
                {option.label}
              </option>
            ))}
          </select>
        ) : isPhoneField ? (
          <PhoneInput
            country={"us"}
            value={inputValue}
            onChange={handlePhoneChange}
            disabled={isDisabled}
            inputProps={{
              name: name,
              required: validationRules.required,
            }}
            containerClass={styles.phoneInputContainer}
            inputClass={styles.phoneInput}
          />
        ) : isDateField ? (
          <WrapperStyleDatePicker>
            <DatePicker
              selected={parseDate(inputValue)}
              onChange={handleDateChange}
              dateFormat="MM-dd-yyyy"
              disabled={isDisabled}
              placeholderText={placeholder || "MM-DD-YYYY"}
              className={`customdatefield ${styles.input} ${
                errorMessage ? styles.error : ""
              }`}
              maxDate={new Date()}
            />
          </WrapperStyleDatePicker>
        ) : (
          <input
            type="text"
            placeholder={placeholder || "Enter text"}
            value={inputValue}
            onChange={handleInputChange}
            disabled={isDisabled}
            className={`${styles.input} ${errorMessage ? styles.error : ""}`}
          />
        )}
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default CustomInputField;
