import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styles from './FileUpload.module.css'
 
const FileUpload = ({ onFileUpload }) => {
  const [uploadingFile, setUploadingFile] = useState(null); 
  const [progress, setProgress] = useState(0); 
  const [errorMessage, setErrorMessage] = useState('');
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessage('Only PDF, DOC, and DOCX files are allowed.');
      setUploadingFile(null);
      setProgress(0);
      return;
    }
 
   
    const file = acceptedFiles[0];
    setUploadingFile(file);
    setProgress(0); 
    setErrorMessage(''); 
    simulateFileUpload(file); 
  };
 
  const simulateFileUpload = (file) => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval); 
          onFileUpload(file); 
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };
 
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf,.doc,.docx', 
    multiple: false,
  });
 
  return (
    <div className={styles["faq-file-upload-container"]} {...getRootProps()}>
      <input {...getInputProps()} />
      {/* <div className={`upload-area ${isDragActive ? 'drag-active' : ''}`}> */}
      <div className={`${styles['upload-area']} ${isDragActive ? styles['drag-active'] : ''}`}>

        <CloudUploadIcon style={{ fontSize: '48px', color: '#3f51b5' }} />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {isDragActive ? 'Drop the file here...' : 'Upload or drag and drop file'}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{ marginTop: 2 }}
        >
          Upload File
        </Button>
      </div>
 
      {errorMessage && (
        <div className={styles["error-message"]} style={{ color: 'red', marginTop: 10 }}>
          <Typography variant="body2">{errorMessage}</Typography>
        </div>
      )}
 
      {uploadingFile && !errorMessage && (
        <div className={styles["upload-progress"]}>
          <Typography variant="body1">
            Uploading: {uploadingFile.name}
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ marginTop: 2 }} />
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            {progress}% Uploaded
          </Typography>
        </div>
      )}
    </div>
  );
};
 
export default FileUpload;