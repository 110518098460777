import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box} from "@mui/material";
import Grid from "@mui/material/Grid2";
import AzureOpenAI from "../../../assets/AzureOpenAI.svg";
import style from "./LLMs.module.css";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LLMs = (props) => {
  const {
    totalTokensConsumed = 0,
    toatalPrice = 0,
    tokensByWeek = {},
  } = props.data || {};

  const getLast7DaysData = () => {
    const today = new Date();
    const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

    const last7Days = Array.from({ length: 7 })
      .map((_, i) => {
        const date = new Date();
        date.setDate(today.getDate() - i);
        return {
          date: date.toISOString().split("T")[0],
          day: daysOfWeek[date.getDay()],
        };
      })
      .reverse();

    const tokenData = last7Days.map(({ date }) => tokensByWeek[date] || 0);
    const dayLabels = last7Days.map(({ day }) => day);

    return { labels: dayLabels, tokenData };
  };

  const { labels, tokenData } = getLast7DaysData();

  const data = {
    labels: labels.length > 0 ? labels : ["No Data"],
    datasets: [
      {
        label: "Tokens Consumed",
        data: tokenData.length > 0 ? tokenData : [0],
        fill: false,
        borderColor: "#3c66fa",
        tension: 0.1,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Day Wise Tokens Consumed",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const LLmsSmallBox = ({ title, number }) => {
    return (
      <div className={style["small-card"]}>
        <img
          src={AzureOpenAI}
          style={{ width: "7vw", marginBottom: "10px" }}
          alt="AzureOpenAI"
        />
        <div>
          <h3>{title}</h3>
          <h2>{number}</h2>
        </div>
      </div>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid
          size={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box display="flex" flexDirection="row">
            <LLmsSmallBox
              title="Total Tokens Consumed"
              number={totalTokensConsumed}
            />
            <LLmsSmallBox
              title="Total Tokens Cost"
              number={`$${toatalPrice.toFixed(4)}`}
            />
          </Box>
        </Grid>
        <Grid size={7} sx={{ padding: "30px 50px" }} container spacing={10}>
          <Line data={data} options={options} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LLMs;
