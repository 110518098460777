import React, { useEffect, useState } from "react";
import TabsContainer from "../../Components/Common/Tabs/Tabs"
import Analytics from "./Analytics/Analytics";
import LLMs from "./LLMs/LLMs";
import { fetchAnalyticsData } from "../../api/api";
 
const Home = () => {
  const [analyticsData, setAnalyticsData] = useState({
    analyticsSummary: {},
    costAnalytics: {},
  });

  useEffect(() => {
    const getAnalyticsData = async () => {
      try {
        const data = await fetchAnalyticsData();
        setAnalyticsData(data?.data?.data || { analyticsSummary: {}, costAnalytics: {} });
      } catch (error) {
        console.error("Error in fetching analytics data:", error);
      }
    };
    getAnalyticsData(); 
  }, []);

  const tabs = [
    { label: "Analytics", content: <Analytics data={analyticsData.analyticsSummary || {}} /> },
    { label: "LLM's", content: <LLMs data={analyticsData.costAnalytics || {}} /> },
  ];
  return (
    <TabsContainer tabs={tabs} />
  );
};
 
export default Home;
 